import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class GutBc extends Component {

render() {
return (

<section id="moreinfo" class="contact-section bg-black">
</section>

);
}
}

export default GutBc;
