import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class Gut5 extends Component {

render() {
return (

<div class="container">

</div>


);
}
}

export default Gut5;
