import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class Gut3 extends Component {

  render() {
    return (

<div class="container">
<div class="d-flex justify-content-center mt-5">

</div>
</div>

);
}
}

export default Gut3;
