import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class Gut1 extends Component {

  render() {
    return (


      <div class="container">
        <br></br>
        <br></br>
    <hr></hr>
      <div class="row">
      <div class="col-md-8 mb-5">
        <p class="bigtextdarkgray"><strong>Claim Services</strong></p>
    <hr></hr>
            <p>OneLaw.us is the landing page for the WorldLaw system of protocols used to enhance legal claim adjudication.</p>
            <p>The starting point is to use a signing identity that is used to authenticate the testimony attached to your documentary evidence. It satisfies most of the Federal Rules of Evidence.</p>
            <a href="https://users.onelaw.us" class="btn btn-primary btn-lg" target="_blank" rel="noopener noreferrer" >Create Signing Identity Now</a>
      </div>
      <div class="col-md-4 mb-5">
      <p class="bigtextdarkgray"><strong>System Support</strong></p>
    <hr></hr>
        <address>
            <strong>The applications are designed to be self-executing and authenticating.</strong>
Visit <a href="https://github.com/worldlaw">https://github.com/worldlaw</a>  and <a href="https://github.com/worldlaw">https://blackboxlaw.com</a> for addtional system use information.
    <br></br>
    To contact us by phone and leave a voice message:
    <br></br>
        </address>

        <address>
          <abbr title="Phone">P: US: (877) 230-0750</abbr>
    <br></br>
    General comments and questions should be directed to:
    <br></br>
          <abbr title="Email">E: questions@onelaw.us</abbr>
            <a href="mailto:questions@onelaw.us"> &nbsp; Send Email</a>
        </address>
      </div>
      </div>

      <div class="row">
      <div class="col-md-4 mb-5">
      <div class="card h-100">
      <img class="card-img-top " src="../img/00017-240w.png" alt="encrypt-upload"></img>
      <div class="card-body">
        <h4 class="card-title">Encrypt and Upload Content</h4>
            <p class="card-text">Encrypt and Upload Content and form-data to public nodes and the Ethereum blockchain</p>
      </div>
      <div class="card-footer">
            <a href="https://tidbits.onelaw.us" class="btn btn-primary" target="_blank" rel="noopener noreferrer" >Encrypt and Upload Now</a>
      </div>
      </div>
      </div>
      <div class="col-md-4 mb-5">
      <div class="card h-100">
      <img class="card-img-top" src="../img/databanks-and-oracles_240w.png" alt="databanks-and-oracles"></img>
      <div class="card-body">
        <h4 class="card-title">Search Public Nodes and Blockchain</h4>
            <p class="card-text">Search for content on public nodes</p>
      </div>
      <div class="card-footer">
            <a href="https://etherscan.io" class="btn btn-primary" target="_blank" rel="noopener noreferrer" >Search Nodes for Content</a>
      </div>
      </div>
      </div>
      <div class="col-md-4 mb-5">
      <div class="card h-100">
      <img class="card-img-top" src="../img/00011-240w.png" alt="prepare-content"></img>
      <div class="card-body">
        <h4 class="card-title">Prepare Content for Court</h4>
            <p class="card-text">Prepare your evidential content files for claim resolution in a court-of-law.</p>
      </div>
      <div class="card-footer">
            <a href="https://evidencer.onelaw.us" class="btn btn-primary" target="_blank" rel="noopener noreferrer" >Prepare Claim Now</a>
      </div>
      </div>
      </div>
      </div>

<div class="d-flex justify-content-center mt-5">

{ this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5 mr-2'
                width='24'
                height='24'
                src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }

<div className="text-black">Current Account: <span id="account">{this.props.account}</span>

</div>
</div>

</div>




);
}
}

export default Gut1;
