import React, { Component } from 'react';


class Footer extends Component {

  render() {
    return (

      <footer class="footer text-center bg-light">
      <div class="container">
      <div class="row">
      <div class="col-lg-10 h-100 text-center text-lg-center my-auto">
           <ul class="list-inline mb-2">
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">WorldLaw</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Users</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Bluefin</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Gumshoe</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Proofer</a>

          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Tidbits</a>
          </li>

          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Judiciary</a>
          </li>
          </ul>
    <hr></hr>
      </div>
      <div class="col-lg-10 h-100 text-center text-lg-center my-auto">
           <ul class="list-inline mb-0">
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i class="fab fa-twitter-square fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i class="fab fa-github fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="wl-more-info-faq.html">
            <i class="fab fa-bitcoin fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i class="fab fa-ethereum fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i class="fas fa-gas-pump fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="wl-more-info-faq.html">
            <i class="fas fa-dice-d6 fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i class="fas fa-upload fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i class="fas fa-file-download fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="wl-more-info-faq.html">
            <i class="fas fa-user-tie fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i class="fas fa-shield-alt fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i class="fas fa-landmark fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="wl-more-info-faq.html">
            <i class="fas fa-sliders-h fa-2x fa-fw "></i>
            </a>
          </li>
          </ul>
    <hr></hr>
      </div>
      </div>
            <p class="text-muted small mb-4 mb-lg-0">Copyright &copy; WorldLaw 2020 - WorldLaw.eth</p>
      </div>
  </footer>





);
}
}

export default Footer;
